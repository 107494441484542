<template>
  <section class="our-stores">
    <div class="container">
      <div v-if="isShowTitle" class="header-block">
        <h2 class="title">{{ $t("ourStores") }}</h2>
        <a class="header-block__link"></a>
      </div>
      <div class="our-stores__buttons">
        <button
          v-for="btn in mobileButtons"
          :key="btn.id"
          class="our-stores__button"
          :class="{ 'is-active': btn.id === selectedShopsView.id }"
          @click="changeViewType(btn)"
        >
          {{ btn.label }}
        </button>
      </div>
      <div v-if="shopTypesList && isShowList" class="our-stores__list">
        <a
          v-for="shopType in shopTypesList"
          :key="shopType.id"
          class="our-stores__link"
          :class="{ 'is-active': selectedShopType.id === shopType.id }"
          @click.prevent="selectShopType(shopType)"
          >{{ shopType.attributes.name }}</a
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ShopTypesList",
  props: {
    isShowTitle: {
      type: Boolean,
      default: true,
    },
    shopTypesList: {
      type: Array,
      required: true,
    },
    isMapShow: {
      type: Boolean,
    },
    isShowList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedShopType: {},
      mobileButtons: [
        { id: 1, label: "Список", type: "list" },
        { id: 2, label: "На карте", type: "map" },
      ],
      selectedShopsView: null,
    };
  },
  created() {
    this.selectedShopType = this.shopTypesList[0];
    this.selectedShopsView = this.mobileButtons[1];
  },
  watch: {
    isMapShow: {
      handler() {
        if (this.isMapShow) {
          this.selectedShopsView = this.mobileButtons[1];
        }
      },
    },
  },
  methods: {
    selectShopType(shopType) {
      this.selectedShopType = shopType;
      this.$emit("select-shop-type", this.selectedShopType);
    },
    changeViewType(btn) {
      this.selectedShopsView = btn;
      this.$emit("change-shops-view", btn.type);
    },
  },
};
</script>

<style lang="scss">
.is-list-active {
  position: relative !important;
  .map-card__addresses {
    display: block !important;
  }
  .map-card__addresses {
    min-height: auto !important;
  }
}
</style>
